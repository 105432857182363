<template>
<div class="wrapper">
  <h4>{{ titleName }}</h4>
  <el-form :model="form" ref="form" label-width="100px">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item  label="字典类型ID">
          <el-input v-model="form.pid" placeholder="请输入字典类型ID" auto-complete="off"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item  label="字典值">
          <el-input v-model="form.dictVal" placeholder="请输入字典值" auto-complete="off"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item  label="字典名称">
          <el-input v-model="form.dictDesc" placeholder="请输入字典名称" auto-complete="off"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item style="text-align: center;">
          <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
          <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions
} from "vuex";

export default {
  name: 'AddOrEdit',
  data() {
    return {
      titleName: '',
      form: {}
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
	searchist() {
	      var list = [];
	      var _list = [];
	      var _this = this;
	      this.seqList.map(function (item) {
	        if (item.plateNo.indexOf(_this.plateNoId) != -1) {
	          //然后把当前json添加到list数组中
	          _list.push(item);
	          list = _list;
	        }
	      });
	      return list;
	    }
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions([
      "addDictServe",
    ]),
	focusEvent1() {
	  this.$refs.xDown1.showPanel();
	},
	getLeaveTime(data) {
	  this.form.leaveTime = data;
	},
	getFactTime(data) {
	  this.form.factTime = data;
	},
    initView() {
      if (this.routeType == 'add') {
        this.titleName = '添加数据字典'
      } else {
        this.titleName = '修改数据字典'
        //this.itemData = JSON.parse(this.$route.query.item);
        //这里调用请求编辑时的详情接口渲染form表单数据
        this.form = {
          pid: '',
          dictVal: '',
          dictDesc: ''
        }
		//this.plateNoId = this.itemData.plate
      }
    },
    submitEvent() {
      let params = this.form
      this.addDictServe(params).then(res => {
        if (res.code == 200) {
          this.$XModal.message({
            message: '保存成功',
            status: "success"
          });
          this.$router.go(-1)
        }
        this.loading = false;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  h4 {
    height: 40px;
    line-height: 40px;
    color: #F26333;
    padding-left: 20px;
  }

  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }
  .my-dropdown1 {
      height: 200px;
      overflow: auto;
      border-radius: 4px;
      background-color: #fff;
      border: 1px solid #dcdfe6;
	  padding: 0 8px;
      li {
        height: 32px;
        line-height: 32px;
        cursor: pointer;
      }
    }
}
</style>
